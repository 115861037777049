import * as React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Carousel from 'react-bootstrap/Carousel';
import Clock from "bootstrap-icons/icons/clock.svg";
import Truck from "bootstrap-icons/icons/truck.svg";
import Award from "bootstrap-icons/icons/award.svg";
import Droplet from "bootstrap-icons/icons/droplet.svg";
import OnlineForm from "../../Online";
import contacts from "../../../lib/contacts";
import PhoneLink from "../../PhoneLink";
import FirstSlideImg from "../../../images/slide2.jpg";
import FirstSlideImgWebp from "../../../images/slide2.jpg.webp";
import FirstSlideSmallImg from "../../../images/slide2_sm.jpg";
import FirstSlideSmallImgWebp from "../../../images/slide2_sm.jpg.webp";
import SecondSlideImg from "../../../images/slide0.png";
import SecondSlideImgWebp from "../../../images/slide0.png.webp";
import SecondSlide1Img from "../../../images/slide0-1.png";
import SecondSlide1ImgWebp from "../../../images/slide0-1.png.webp";
import Slide3Img from "../../../images/slide3.jpg";
import Slide3ImgWebp from "../../../images/slide3.jpg.webp";
import Slide3SmallImg from "../../../images/slide3_sm.jpg";
import Slide3SmallImgWebp from "../../../images/slide3_sm.jpg.webp";
import Slide4Img from "../../../images/slide4.jpg";
import Slide4ImgWebp from "../../../images/slide4.jpg.webp";
import Slide4SmallImg from "../../../images/slide4_sm.jpg";
import Slide4SmallImgWebp from "../../../images/slide4_sm.jpg.webp";
import Slide5Img from "../../../images/slide5.jpg";
import Slide5ImgWebp from "../../../images/slide5.jpg.webp";
import Slide5SmallImg from "../../../images/slide5_sm.jpg";
import Slide5SmallImgWebp from "../../../images/slide5_sm.jpg.webp";

import InstagramIcon from "bootstrap-icons/icons/instagram.svg";
import "./header.scss";

function IndexHeader() {
  const [index, setIndex] = React.useState(0);

  const handleSelect = (selectedIndex) => {
    setIndex(selectedIndex);
  };

  return (
    <header id="section-header" className={"section-header"}>
      <Carousel activeIndex={index} onSelect={handleSelect} interval={null} controls={false} pause="hover" touch>
        <Carousel.Item className="carousel-item_main">
          <picture>
            <source srcSet={FirstSlideSmallImg} media="(max-width: 1199px)" type="image/jpg"/>
            <source srcSet={FirstSlideSmallImgWebp} media="(max-width: 1199px)" type="image/webp"/>
            <source srcSet={FirstSlideImg} media="(min-width: 1200px)" type="image/jpg"/>
            <source srcSet={FirstSlideImgWebp} media="(min-width: 1200px)" type="image/webp" />
            <img src={FirstSlideImg} alt="Чистка сажевого фильтра и катализатора" />
          </picture>

          <Container>
            <Row>
              <h1>Чистка сажевого фильтра и катализатора в Казахстане <small>выгоднее замены на новый</small></h1>
              <p className="slogan">Сохранение заводских настроек автомобиля и экологических норм эксплуатации</p>
            </Row>

            <Row>
              <Col xl={8}>
                <ul className="advantages">
                  <li>
                    <i><Clock/></i>
                    <div>
                      <span className="header">В течениие 15 минут</span>
                      <span className="description">
                        Диагностика снятого сажевого фильтра в присутствии клиента<br/>
                        Процесс чистки от 1 до 3 часов
                      </span>
                    </div>
                  </li>

                  <li>
                    <i><Truck/></i>
                    <div>
                      <span className="header">Доставка по Казахстану</span>
                      <span className="description">
                        Возможность БЕСПЛАТНОЙ доставки по вашему адресу в городе Алматы <br/>
                        в необходимое для вас время, уточняйте у менеджера
                      </span>
                    </div>
                  </li>

                  <li>
                    <i><Award/></i>
                    <div>
                      <span className="header">Удаление 99 % загрязнений</span>
                      <span className="description">
                        Возникающих из сажи, серы, масла из двигателя и охлаждающей жидкости,<br/>
                        без повреждения картриджа фильтра
                      </span>
                    </div>
                  </li>

                  <li>
                    <i><Droplet/></i>
                    <div>
                      <span className="header">Очистка DPF / FAP / SCR / KAT на современном Европейском оборудовании</span>
                      <span className="description">
                        С применением  экологически чистых моющих средств, безопасных для фильтрующего элемента.<br/>
                        Предоставляются результаты чистки с показателями ДО и ПОСЛЕ промывки
                      </span>
                    </div>
                  </li>
                </ul>
              </Col>

              <Col xl={4}>
                <OnlineForm
                  subject="Записаться онлайн"
                  title={<>
                    <strong>Оставить заявку</strong>
                    или записаться по телефону <PhoneLink phone={contacts.phones[0]}/>
                  </>}
                />
              </Col>
            </Row>
          </Container>
        </Carousel.Item>

        <Carousel.Item className="carousel-item_economy">
          <Container>
            <p className="h1">Как сэкономить 1,8 млн ₸ <small>на покупке нового сажевого фильтра?</small></p>
            <div className="details">
              <ul>
                <li>удаление до 99% отложений</li>
                <li>не требуется разрезать корпус фильтра</li>
                <li>результат подтверждается измерениями оборудования</li>
              </ul>

              <a href="https://www.instagram.com/p/Cv7WnIztoz_/?igshid=MzRlODBiNWFlZA==" target="_blank" rel="noreferrer">
                <InstagramIcon/>
                <span>Подробнее</span>
              </a>
            </div>
            
            <picture>
              <source srcSet={SecondSlideImgWebp} type="image/webp"/>
              <img src={SecondSlideImg} alt="результат чистки сажевого фильтра" className="result"/>
            </picture>

            <picture>
              <source srcSet={SecondSlide1ImgWebp} type="image/webp"/>
              <img src={SecondSlide1Img} alt="результат чистки сажевого фильтра" className="equipment"/>
            </picture>
          </Container>
        </Carousel.Item>

        <Carousel.Item className="carousel-item_second">
          <div className={'d-flex justify-content-center align-items-center w-100 h-100'}>
            <picture>
              <source srcSet={Slide3SmallImg} media="(max-width: 1199px)" type="image/jpg"/>
              <source srcSet={Slide3SmallImgWebp} media="(max-width: 1199px)" type="image/webp"/>
              <source srcSet={Slide3Img} media="(min-width: 1200px)" type="image/jpg"/>
              <source srcSet={Slide3ImgWebp} media="(min-width: 1200px)" type="image/webp"/>
              <img src={Slide3Img} alt="Чистка сажевого фильтра и катализатора"/>
            </picture>

            <Container>
              <Row>
                <h2>Не удаляй! <br/>Мы почистим</h2>
                <p className="slogan">Отсутствие сажевого фильтра гораздо опаснее,чем вы думаете</p>

                <a href={"https://smturbo.kz/blog/nelzya-udalit-ochistit"} className={'btn btn-lg btn-primary'}
                   target={"_blank"} rel="noreferrer"
                >
                  Подробнее
                </a>
              </Row>
            </Container>
          </div>
        </Carousel.Item>

        <Carousel.Item className="carousel-item_third">
          <div className={'d-flex justify-content-center align-items-center w-100 h-100'}>
            <picture>
              <source srcSet={Slide4SmallImg} media="(max-width: 1199px)" type="image/jpg"/>
              <source srcSet={Slide4SmallImgWebp} media="(max-width: 1199px)" type="image/webp"/>
              <source srcSet={Slide4Img} media="(min-width: 1200px)" type="image/jpg"/>
              <source srcSet={Slide4ImgWebp} media="(min-width: 1200px)" type="image/webp"/>
              <img src={Slide4Img} alt="Чистка сажевого фильтра и катализатора"/>
            </picture>

            <Container className={'whyUs-container'}>
              <h2>Почему мы? <small>Почему стоит почистить сажевый фильтр именно у нас?</small></h2>

              <div className={'items-wrapper'}>
                <p className={'item'}>
                  <span className={'num'}>15<i className={'d-none'}> минут</i></span>
                  <span className={'title'}>диагностика снятого фильтра в присутствии клиента в течение 15 минут</span>
                </p>

                <p className={'item'}>
                  <span className={'num'}>99<i className={'d-none'}> % загрязнений</i></span>
                  <span className={'title'}>удаление 99% загрязнений на Европейском оборудовании Total DPF</span>
                </p>

                <p className={'item'}>
                  <span className={'num'}>6<i className={'d-none'}> месяцев</i></span>
                  <span className={'title'}>гарантийный срок на выполненные работы 6 месяцев или пробег не более 50 000 км</span>
                </p>

                <p className={'item'}>
                  <span className={'num'}>1<i className={'d-none'}> час</i></span>
                  <span className={'title'}>процесс чистки занимает от 1 до 3 часов</span>
                </p>
              </div>
            </Container>
          </div>
        </Carousel.Item>

        <Carousel.Item className="carousel-item_fourth">
          <div className={'d-flex justify-content-center align-items-center w-100 h-100'}>
            <picture>
              <source srcSet={Slide5SmallImg} media="(max-width: 1199px)" type="image/jpg"/>
              <source srcSet={Slide5SmallImgWebp} media="(max-width: 1199px)" type="image/webp"/>
              <source srcSet={Slide5Img} media="(min-width: 1200px)" type="image/jpg"/>
              <source srcSet={Slide5ImgWebp} media="(min-width: 1200px)" type="image/webp"/>
              <img src={Slide5Img} alt="Чистка сажевого фильтра и катализатора"/>
            </picture>

            <Container className={'whyUs-container'}>
              <h2>Гидродинамическая чистка сажевого фильтра</h2>

              <a className={'btn btn-lg btn-primary'}
                 href={"https://www.youtube.com/watch?v=NKuK_Sf8340"}
                 target={"_blank"}
                 rel="noreferrer"
              >
                смотреть процесс чистки
              </a>
            </Container>
          </div>
        </Carousel.Item>
      </Carousel>
    </header>
  )
}

export default IndexHeader;